import React, { useState, useEffect } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import ColumnFilter from "../../UserManagement/ColumnFilter"
import { numberUSFormatting, oneDecimal, twoDecimal } from "../../../utils/formatters/valueFormatters";
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";
import { cellRendererForRows, findSum } from "../../../utils/commonUtilities";
import Indicator from "../../../components/IndicatorComponent/Indicator";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductListTable = (props) => {
    const { initialHiddenColumns } = props
    const [tableColums, setTableColumns] = useState([])

    const productDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [{
            Header: " ",
            sticky: "left",
            columns: [
                {
                    Header: `${updateArticleName(false)} ID`,
                    accessor: 'article',
                    Cell: (instance) => (
                        <div className={instance?.row?.original?.flag ? `highlight_row`:""}>
                            <div className="clickable" onClick={() => props.getProductDetails(instance.row?.original)}>{instance.row?.original?.article}</div>
                        </div>
                    ),
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
                    filter: 'bulkFilterCommaSeperated',
                },
                {
                    Header: 'Description',
                    accessor: 'style_name',
                    width: 200,
                    Filter: ColumnFilter,
                    Cell: (instance) => cellRendererForRows(instance),
                },
                {
                    Header: 'Style Color',
                    accessor: 'color',
                    Filter: ColumnFilter,
                    Cell: (instance) => cellRendererForRows(instance),
                },
            ]
        }]
        },
        {
            Header: 'Pack ID',
            accessor: 'packs',
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Demand Type',
            accessor: 'demand_type',
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Store Group',
            accessor: 'store_groups',
            Cell: (instance) => <ReactTollTip {...instance}/>,
            width: 300,
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: '# Stores',
            accessor: 'store_group_store_count',
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },

        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: "l6_name",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        // {
        //     Header: "LW Sales Units",
        //     accessor: "lw_qty",
        //     Filter: ColumnFilter,
        // },
        {
            Header: '# Allocated Stores',
            accessor: 'store_code',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Ecom Inventory',
            accessor: "ecom_inv",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Inv. Source',
            accessor: "inventory_source",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: "Allocated Quantity",
            id: "allocated_split",
            columns: [
                {
                    Header: '# Packs Allocated',
                    // accessor: 'packs_allocated',
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    id: "packs_split"
                },
                {
                    Header: '# Eaches Allocated',
                    // accessor: 'loose_units_allocated',
                    // Cell: (inst) => numberUSFormatting(inst?.value),
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    id: "eaches_split"
                },
                {
                    Header: 'Total Allocated',
                    // accessor: 'allocated_quantity',
                    // Cell: (inst) => numberUSFormatting(inst?.value),
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    Cell: (instance) => cellRendererForRows(instance),
                }
            ]
        },
        {
            Header: 'Net DC Available',
            identify: "dc_available",
            columns:[]
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),

        },
        {
            Header: 'Forecasted APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos,
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Size',
            identify: "size",
            columns: []
        },
    ])

    const setSizeColumns = () => {
        if (props.columns) {
            return true
        }
        // let columns = props.columns ? props.columns : productDatacolumns
        else if (props.sizes.length) {
            let sizeIndex = productDatacolumns.findIndex(col => col.identify === "size");
            let dcIndex = productDatacolumns.findIndex(col => col.identify === "dc_available");

            props.sizes.forEach((s, index) => {
                productDatacolumns[sizeIndex].columns.push({
                    Header: s,
                    accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    Cell: (instance) => cellRendererForRows(instance),
                })
            })
            props?.dc_list?.length && props?.dc_list?.map((val) =>{
                if(val){
                //     productDatacolumns[16].columns.push({
                //       Header: val + " ",
                //       accessor: row => row.dc?.[val]?.["allocated_quantity"] || 0,
                //       Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                //       Filter: NumberRangeColumnFilter,
                //       filter: 'between',
                //   })
                  productDatacolumns[dcIndex].columns.push({
                    Header: val,
                    accessor: row => row.dc?.[val]?.["dc_available"] || 0,
                    Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    Cell: (instance) => cellRendererForRows(instance),
                  })
                }  
            })
            productDatacolumns.splice(13, 0, {
                Header: "Total Allocated Units",
                accessor: row => findSum(row?.size_value) || 0,
                Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                Cell: (instance) => cellRendererForRows(instance),
            })          
            // setTableColumns(columns)
            let allocatedIndex = productDatacolumns.findIndex(col => col.id === "allocated_split")

            productDatacolumns[allocatedIndex].columns[0].columns = []
            productDatacolumns[allocatedIndex].columns[1].columns = []
            productDatacolumns[allocatedIndex].columns[2].columns = []

            props?.dc_list?.forEach((dc) => {
              productDatacolumns[allocatedIndex].columns[0].columns.push({
                Header: dc,
                id: dc + "packs",
                accessor: (row) => row.packs_allocated?.[dc],
                Filter: NumberRangeColumnFilter,
                filter: "between",
                width: 150,
                Cell: (instance) => cellRendererForRows(instance),
              });
              productDatacolumns[allocatedIndex].columns[1].columns.push({
                Header: dc,
                id: dc + "eaches",
                accessor: (row) => row.loose_units_allocated?.[dc],
                Filter: NumberRangeColumnFilter,
                filter: "between",
                width: 150,
                Cell: (instance) => cellRendererForRows(instance),
              });
              productDatacolumns[allocatedIndex].columns[2].columns.push({
                Header: dc + " ",
                accessor: (row) => row.dc?.[dc]?.["allocated_quantity"] || 0,
                Cell: (inst) => {
                    const value = inst?.value ? numberUSFormatting(inst?.value) : 0;
                    return cellRendererForRows({...inst, value});
                },
                Filter: NumberRangeColumnFilter,
                filter: "between",
              });
            });
        
        
            return true
        }
        return false
    }

    return (

        setSizeColumns() &&
        <>
            // setSizeColumns() &&
            <ReactTableWithPlugins
                hideOptions={props.sizes}
                shouldPagination
                // embededScroll
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '60rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                totalRecordsLen={props.data ? props.data.length : 0}
                data={props.data ? props.data : []}
                // sortBy={[{ id: 'allocated_quantity', desc: true }]}
                columns={props.columns ? props.columns : productDatacolumns}
                renderMarkup='TableMarkup'
                keyRT="sortAndSearch"
                tableId="finalize_product_list"
                initialHiddenColumns={initialHiddenColumns || []}
            />
            {!props.viewAllocationCall &&<Indicator className="custom-indicator__styles" text = "Store Inventory + Allocated qty is less than the Min Constraint" color = "lightgrey" />}
        </>
    )

}

export default ProductListTable