import React, { useEffect, useState } from "react";
import "../Finalize.css";
import { connect } from "react-redux";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable";
import {
  getPastAllocationPO,
  getStoreData,
  getStoreDetails,
  getStoreProductDetails,
  saveStoreData,
} from "../FinalizeAction";
import PageLoader from "../../../components/Loader/PageLoader";
import ProductListTable from "../ProductView/ProductListTable";
import ProductDetailsTable from "../ProductView/ProductDetailsTable";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import {
  numberUSFormatting,
  oneDecimal,
} from "../../../utils/formatters/valueFormatters";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import DateInput from "../../../components/Table/Cellrenderer/DateInput";
import SetAllModal from "./SetAllModal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import DatePicker, { today } from "../../../components/DatePicker/DatePicker";
import moment from "moment";
import { cloneDeep, forEach } from "lodash";
import { cellRendererForRows, dateFormat, findSum, getNumberOfRecordsPerPages } from "../../../utils/commonUtilities";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const getTernaryValue = (p_value, p_alternative = null, p_return = null) => {
  return p_value ? (p_return ? p_return : moment(p_value).format("MM-DD-YYYY")) : p_alternative || "-";
};

const StoreView = (props) => {
  const [selectedStore, setStore] = useState(null);
  const [RTInstance, setRTinstance] = useState({});
  const [showSetAll, setShowSetAll] = useState(false);
  const [dcSA, setDcSA] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [dateSA, setDateSA] = useState(null);
  const [dcSAReq, setDcSAReq] = useState(null);
  const [keyRT, setKeyRT] = useState("");
  const [dcDelivery, setDcDelivery] = useState(null);
  const [dateDelivery, setDateDelivery] = useState(null);
  const [dcDeliveryReq, setDcDeliveryReq] = useState(null);
  const [initialHiddenColumns, setHiddenColumns] = useState([]);
  const [updatedId, setUpdatedId] = useState([])

  const setTableInstance = (instance) => {
    props.setTableInstance(instance);
  };

  useEffect(() => {
    if (props.viewAllocationCall) {
      setKeyRT("sortAndSearch");
    } else {
      setKeyRT("storeView");
    }
  }, [props.viewAllocationCall]);

  useEffect(() => {
    if (props.storeDataError) {
      Notify.error("Error in Saving Dates !!");
    }
    if (props.saveSuccess && keyRT === "storeView") {
      Notify.success("Saved Successfully!!");
    }
    setUpdatedId([]);
  }, [props.storeDataError, props.saveSuccess]);

  useEffect(() => {
    setStoreData(props?.storeData);

    let hideColumns = [];
    // If NOT the PO flow hide the Delivery Date Column.
    if (!(props.isPOASNFlow || props.isPOASN) || props.isAutoApprove) {
      props.storeDCs.map((dc) => {
        hideColumns = [...hideColumns, `Delivery-Date DC - ${dc}`];
      });
    }
    hideColumns = props.viewAllocationCall ? [...hideColumns, "allocated_reserve"] : hideColumns
    setHiddenColumns(hideColumns);
  }, [props?.storeData]);

  useEffect(() => {
    props.isFinalized && setHiddenColumns([...initialHiddenColumns, "allocated_reserve"]);
  }, [props.isFinalized])

  useEffect(() => {
    if (props.activeTab == "fsv" && props.isPOASNFlow) {
      let req = {
        allocationcode: props.allocationCode,
      };
      if (props.isPOASNFlow) {
        req["article"] = props.articles;
      }
      props.getStoreData(req);
    }
    if (
      props.activeTab == "fsv" &&
      !(props.viewAllocationCall && storeData.length) &&
      !props.isPOASNFlow
    ) {
      let req = {
        allocationcode: props.allocationCode,
      };
      if (props.viewAllocationCall) {
        req["page"] = "viewPastAllocation";
        if (props.releasedDate) {
          req["released_date"] = props.releasedDate;
        }
        // props.getPastAllocationPO({ allocationcode: props.allocationCode, articles:props.rowArticles})
      }
      props.getStoreData(req);
    }
  }, [props.activeTab, props.isPOASNFlow]);

  const getStoreDetails = (isStore, storeDetail) => {
    if (isStore) {
      setStore(storeDetail);
    }
    let req = {
      allocationcode: props.allocationCode,
      store_code: isStore ? storeDetail?.store_code : selectedStore?.store_code,
    };
    if (props.isPOASNFlow) {
      req["article"] = props.articles;
    }
    if (props.viewAllocationCall) {
      req["page"] = "viewPastAllocation";
    }
    if (props.releasedDate) {
      req["released_date"] = props.releasedDate;
    }
    props.getStoreDetails(req);
  };

  const getStoreProductDetails = (isSetInstance, instance) => {
    let req = {
      allocationcode: props.allocationCode,
      article: isSetInstance ? instance.article : RTInstance.article,
      store_code: selectedStore?.store_code,
    };
    if (props.viewAllocationCall) {
      req["page"] = "viewPastAllocation";
    }
    if (props.releasedDate) {
      req["released_date"] = props.releasedDate;
    }
    props.isPOASNFlow && (req["partial_articles"] = props.articles)
    props.getStoreProductDetails(req);
    if (isSetInstance) {
      setRTinstance({
        allocationcode: props.allocationCode,
        store_code: selectedStore?.store_code,
        article: instance.article,
        color: instance.color,
      });
    }
  };

  const storeDatacolumns = React.useMemo(() => [
    {
      Header: " ",
      sticky: "left",
      columns: [{
      Header: "  ",
      sticky: 'left',
      columns: [
        {
          Header: "Store Number",
          accessor: "store_code",
          Cell: (instance) => (
            <div>
              <div
                className="clickable"
                onClick={() => getStoreDetails(true, instance.row?.original)}
              >
                {instance.row?.original?.store_code}
              </div>
            </div>
          ),
          width: 200,
          Filter: (instance) => (
            <ColumnFilter {...instance} placeholder="Search in bulk..." />
          ),
          filter: "bulkFilterSpaceSeperated",
        },
      ],
    }]
    },
    {
      // Header: " ",
      // sticky: "left",
      // columns: [
      //   {
          Header: "Store Name",
          accessor: "store_name",
          Filter: ColumnFilter,
      //   },
      // ],
    },
    {
      // Header: " ",
      // sticky: "left",
      // columns: [
      //   {
          Header: "Store Grade",
          accessor: "store_grade",
          Filter: ColumnFilter,
          filter: "exactText",
      //   },
      // ],
    },
    {
      // Header: " ",
      // sticky: "left",
      // columns: [
      //   {
          Header: "Climate",
          accessor: "climate",
          Filter: ColumnFilter,
          filter: "exactText",
      //   },
      // ],
    },
    {
      // Header: " ",
      // sticky: "left",
      // columns: [
      //   {
          Header: "Country",
          accessor: "country",
          Filter: ColumnFilter,
      //   },
      // ],
    },
    {
      // Header: " ",
      // sticky: "left",
      // columns: [
      //   {
          Header: "# Style Color",
          accessor: "style_color_cnt",
          Filter: ColumnFilter,
      //   },
      // ],
    },
    {
      Header: "Original APS",
      accessor: (row) =>
        row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Actual APS",
      accessor: (row) =>
        row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "OH",
      accessor: "oh",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "IT",
      accessor: "it",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "OO",
      accessor: "oo",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: 'OH+IT+OO',
      id: `OH+IT+OO`,
      accessor: row => Number(row.it) + Number(row.oh) + Number(row.oo),
      Filter: NumberRangeColumnFilter,
      filter: 'between',
   },
    {
      Header: "LW Sales Units",
      accessor: "lw_qty",
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    {
        Header: "WTD Sales Units",
        accessor: "tw_qty",
        Cell: (instance) => (
            <div>
                {instance?.row?.original.tw_qty !== null ? instance?.row?.original.tw_qty: "-"}   
            </div>
        ),
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    },
    {
        Header: 'Max Constraint',
        accessor: 'max',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    },
    {
      Header: "Min Constraint",
      accessor: "min",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Allocated Total Quantity",
      id: "allocated_split",
      columns: [
        {
          Header: "# Packs Allocated",
          // accessor: "packs_allocated",
          // Filter: NumberRangeColumnFilter,
          // filter: "between",
        },
        {
          Header: "# Eaches Allocated",
          // accessor: "loose_units_allocated",
          // Cell: (inst) => numberUSFormatting(inst?.value),
          // Filter: NumberRangeColumnFilter,
          // filter: "between",
        },
        {
          Header: 'Total Allocated',
          // accessor: 'allocated_total',
          // Cell: (inst) => numberUSFormatting(inst?.value),
          // Filter: NumberRangeColumnFilter,
          // filter: 'between',
      }
      ],
    },
    {
      Header: "Min Allocation",
      accessor: "allocated_for_min",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Wos Allocation",
      accessor: "allocated_for_wos",
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Target WOS",
      accessor: (row) => row.target_wos,
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Forecasted WOS",
      accessor: (row) => row.actual_wos,
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Shipping Dates",
      identify: "shipping_dates",
      columns: [],
    },
    {
      Header: "Delivery Dates",
      identify: "delivery_dates",
      columns: [],
    },
  ]);

  const storeProductDatacolumns = React.useMemo(() => [
    {
      Header: " ",
      sticky: "left",
      columns: [{
      Header: " ",
      sticky: "left",
      columns: [
        {
          Header: `${updateArticleName(false)} ID`,
          accessor: "article",
          Cell: (instance) => (
            <div className={instance?.row?.original?.flag ? `highlight_row`:""}>
              <div
                className="clickable"
                onClick={() =>
                  getStoreProductDetails(true, instance.row?.original)
                }
              >
                {instance.row?.original?.article}
              </div>
            </div>
          ),
          Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
          filter: 'bulkFilterCommaSeperated',
        },
        {
          Header: "Description",
          accessor: "style_name",
          width: 200,
          Filter: ColumnFilter,
          Cell: (instance) => cellRendererForRows(instance),
        },
        {
          Header: "Style Color",
          accessor: "color",
          Filter: ColumnFilter,
          Cell: (instance) => cellRendererForRows(instance),
        },
      ],
    }]
    },
    {
      Header: "LW Sales Units",
      accessor: "lw_qty",
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
        Header: "WTD Sales Units",
        accessor: "tw_qty",
        Cell: (instance) => (
            <div className={instance?.row?.original?.flag ? `highlight_row`:""}>
                {instance?.row?.original.tw_qty !== null ? instance?.row?.original.tw_qty: "-"}   
            </div>
        ),
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    },
    {
      Header: 'OH',
      accessor: 'oh',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: 'IT',
      accessor: 'it',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: 'OO',
      accessor: 'oo',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
        Header: 'Max Constraint',
        accessor: 'max',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: (instance) => cellRendererForRows(instance),
    },
    
    {
      Header: "Min Constraint",
      accessor: "min",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
        Header: "Allocated Reserve",
        accessor: "allocated_reserve",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Ecom Inventory",
      accessor: "ecom_inv",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Inv. Source",
      accessor: "inventory_source",
      Filter: ColumnFilter,
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Allocated Total Quantity",
      id: "allocated_split",
      columns: [
        {
          Header: " ",
          columns: [{
            Header: "Pack ID",
            accessor: "packs",
            Filter: ColumnFilter,
            Cell: (instance) => cellRendererForRows(instance),
          }]
        },
        {
          Header: "# Packs Allocated",
          // accessor: "packs_allocated",
          // Filter: NumberRangeColumnFilter,
          // filter: "between",
          Cell: (instance) => cellRendererForRows(instance),
        },
        {
          Header: "# Eaches Allocated",
          // accessor: "loose_units_allocated",
          // Cell: (inst) => numberUSFormatting(inst?.value),
          // Filter: NumberRangeColumnFilter,
          // filter: "between",
          Cell: (instance) => cellRendererForRows(instance),
        },
        {
          Header: 'Total Allocated',
        }
        // {
        //     Header: 'Total Allocated Units',
        //     accessor: 'allocated_quantity',
        //     Cell: (inst) => numberUSFormatting(inst?.value),
        //     Filter: NumberRangeColumnFilter,
        //     filter: 'between',
        //     width: 200
        // }
      ],
    },
    {
      Header: "Net DC Available",
      columns: [],
      identify: "dc_available"
      // accessor: 'dc_available',
      // Cell: (inst) => numberUSFormatting(inst?.value),
      // Filter: NumberRangeColumnFilter,
      // filter: 'between',
    },
    {
      Header: "Original APS",
      accessor: (row) =>
        row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Forecasted APS",
      accessor: (row) =>
        row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Target WOS",
      accessor: (row) => row.target_wos,
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Forecasted WOS",
      accessor: (row) => row.actual_wos,
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: (instance) => cellRendererForRows(instance),
    },
    {
      Header: "Size",
      identify: "size",
      columns: [],
    },
  ]);

  const allocationDataColumns = React.useMemo(() => [
    {
      Header: "# Style Color",
      accessor: "style_color",
    },
    {
      Header: "# Allocated Stores",
      accessor: "store_cnt",
    },
    {
      Header: "StyleColor depth per store",
      accessor: "style_color_depth_per_store",
      width: 200,
    },
    {
      Header: "Ecom Inventory",
      accessor: "ecom_inventory",
    },
    {
      Header: "Allocated Quantity",
      columns: [],
      // accessor: "allocated_units_total",
      // Cell: (inst) => numberUSFormatting(inst?.value)
    },
    {
      Header: "Net DC Available",
      columns: [],
      // accessor: "inventory_available",
      // Cell: (inst) => numberUSFormatting(inst?.value)
    },
    {
      Header: "Store Grade - Allocation Qty",
      columns: [],
    },
    {
      Header: "Store Grade - Allocation Index",
      columns: [],
    },
  ]);

  const setProductListSizeColumns = () => {
    // let columns = props.columns ? props.columns : productDatacolumns
    if (props.storeDetails.sizes.length) {
      let sizeIndex = storeProductDatacolumns.findIndex(col => col.identify === "size");
      let dcIndex = storeProductDatacolumns.findIndex(col => col.identify === "dc_available");

      props.storeDetails.sizes.forEach((s, index) => {


        storeProductDatacolumns[sizeIndex].columns.push({
          Header: s,
          accessor: (row) =>
            row.size_value[index]
              ? Number(row.size_value[index]).toFixed(0)
              : 0,
          Filter: NumberRangeColumnFilter,
          filter: "between",
          Cell: (instance) => cellRendererForRows(instance),
        });
      });
      props?.storeDetails?.dc_list?.length &&
        props?.storeDetails?.dc_list?.map((val) => {
          if (val) {
            // storeProductDatacolumns[7].columns.push({
            //   Header: val + " ",
            //   accessor: (row) => row.dc?.[val]?.["allocated_quantity"] || 0,
            //   Cell: (inst) =>
            //     inst?.value ? numberUSFormatting(inst?.value) : 0,
            //   Filter: NumberRangeColumnFilter,
            //   filter: "between",
            //   width: 200,
            // });
            let allocatedIndex = storeProductDatacolumns.findIndex(
              (col) => col.id === "allocated_split"
            );
            storeProductDatacolumns[allocatedIndex].columns[1].columns = []
            storeProductDatacolumns[allocatedIndex].columns[2].columns = []
            storeProductDatacolumns[allocatedIndex].columns[3].columns = []
    
              storeProductDatacolumns[allocatedIndex].columns[1].columns.push({
                Header: val,
                id: val + "packs",
                accessor: (row) => row.packs_allocated?.[val],
                Filter: NumberRangeColumnFilter,
                filter: "between",
                width: 150,
                Cell: (instance) => cellRendererForRows(instance),
              });
              storeProductDatacolumns[allocatedIndex].columns[2].columns.push({
                Header: val,
                id: val + "eaches",
                accessor: (row) => row.loose_units_allocated?.[val],
                Filter: NumberRangeColumnFilter,
                filter: "between",
                width: 150,
                Cell: (instance) => cellRendererForRows(instance),
              });
              storeProductDatacolumns[allocatedIndex].columns[3].columns.push({
                Header: val + " ",
                accessor: (row) => row.dc?.[val]?.["allocated_quantity"] || 0,
                Cell: (inst) =>
                  inst?.value ? numberUSFormatting(inst?.value) : 0,
                Filter: NumberRangeColumnFilter,
                filter: "between",
                width: 200,
                Cell: (instance) => cellRendererForRows(instance),
              });
            storeProductDatacolumns[dcIndex].columns.push({
              Header: val,
              accessor: (row) => row.dc?.[val]?.["dc_available"] || 0,
              Cell: (inst) =>
                inst?.value ? numberUSFormatting(inst?.value) : 0,
              Filter: NumberRangeColumnFilter,
              filter: "between",
              Cell: (instance) => cellRendererForRows(instance),
            });
          }
        });
      // setTableColumns(columns)
      return true;
    }
    return false;
  };

  const callAllStoreApis = () => {
    let req = {
      allocationcode: props.allocationCode,
    };
    if (props.isPOASNFlow) {
      req["article"] = props.articles;
    }
    props.getStoreData(req);
    getStoreDetails(false);
    getStoreProductDetails(false);
  };

  const setDateColumns = () => {
    if (storeData.length) {
      let data = storeData[0];
      let dc_names = data.dc_names;
      let storeDCs = props?.storeDCs;
      let shipDateIndex = storeDatacolumns.findIndex(col => col.identify === "shipping_dates");
      let deliveryDateIndex = storeDatacolumns.findIndex(col => col.identify === "delivery_dates");

      storeDCs.map((dc, index) => {
        storeDatacolumns[shipDateIndex].columns.push({
          Header: `DC - ${dc}`,
          // accessor: `DC - ${dc}`,
          Cell: (instance) =>
            props.viewAllocationCall ? (
              <>{getTernaryValue(instance.row.original?.[`DC - ${dc}`], "-")}</>
            ) : instance.row.original?.[`DC - ${dc}`] ? (
              <DateInput
                {...instance}
                value={
                  instance.row.original?.[`DC - ${dc}`]
                  // || instance.row.original.dates[index]
                }
                dateFormat={"MM-DD-YYYY"}
                changeHandler={(rowIdx, columnId, value) => {
                  setDateSA(dateFormat(value));
                  instance.updateMyData(rowIdx, `DC - ${dc}`, dateFormat(value));
                  setStoreData((oldData) => {
                    let newData = cloneDeep(oldData);
                    newData[rowIdx][columnId] = value;
                    return newData;
                  })
                  setUpdatedId((old) => {
                    return [...old, instance.row.original?.store_code]
                })
                }}
                isDisabled={props.isFinalized}
              />
            ) : (
              "-"
            ),
          width: 200,
          disableFilters: true,
        });
      });

      // For Delivery Date
      storeDCs.map((dc, index) => {
        storeDatacolumns[deliveryDateIndex].columns.push({
          Header: `DC - ${dc} `, // Added black space at the end to re-use the same header name
          accessor: `Delivery-Date DC - ${dc}`,
          Cell: (instance) =>
            props.viewAllocationCall ? (
              <>
                {getTernaryValue(
                  instance.row.original?.[`Delivery-Date DC - ${dc}`],
                  "-"
                )}
              </>
            ) : instance.row.original?.[`Delivery-Date DC - ${dc}`] ? (
              <DateInput
                {...instance}
                value={instance.row.original?.[`Delivery-Date DC - ${dc}`]}
                dateFormat={"MM-DD-YYYY"}
                changeHandler={(rowIdx, columnId, value) => {
                  setDateDelivery(dateFormat(value));
                  instance.updateMyData(
                    rowIdx,
                    `Delivery-Date DC - ${dc}`,
                    dateFormat(value)
                  );
                  setStoreData((oldData) => {
                    let newData = cloneDeep(oldData);
                    newData[rowIdx][columnId] = value;
                    return newData;
                  })
                  setUpdatedId((old) => {
                    return [...old, instance.row.original?.store_code]
                })
                }}
                isDisabled={props.isFinalized}
              />
            ) : (
              "-"
            ),
          width: 200,
          disableFilters: true,
        });
      });

      storeDCs?.length &&
        storeDCs?.map((val) => {
          if (val) {
            // storeDatacolumns[16].columns.push({
            //   Header: val,
            //   accessor: (row) => row.dc?.[val]?.["allocated_total"] || 0,
            //   Cell: (inst) =>
            //     inst?.value ? numberUSFormatting(inst?.value) : 0,
            //   Filter: NumberRangeColumnFilter,
            //   filter: "between",
            // });
          }
        });
        let allocatedIndex = storeDatacolumns.findIndex(
          (col) => col.id === "allocated_split"
        );
        storeDatacolumns[allocatedIndex].columns[0].columns = []
        storeDatacolumns[allocatedIndex].columns[1].columns = []
        storeDatacolumns[allocatedIndex].columns[2].columns = []

        storeDCs?.forEach((dc) => {
          storeDatacolumns[allocatedIndex].columns[0].columns.push({
            Header: dc,
            id: dc + "packs",
            accessor: (row) => row.packs_allocated?.[dc],
            Filter: NumberRangeColumnFilter,
            filter: "between",
            width: 150,
          })
          storeDatacolumns[allocatedIndex].columns[1].columns.push({
            Header: dc,
            id: dc + "eaches",
            accessor: (row) => row.loose_units_allocated?.[dc],
            Filter: NumberRangeColumnFilter,
            filter: "between",
            width: 150
          })
          storeDatacolumns[allocatedIndex].columns[2].columns.push({
            Header: dc + " ",
            accessor: (row) => row.dc?.[dc]?.["allocated_total"] || 0,
            Cell: (inst) =>
              inst?.value ? numberUSFormatting(inst?.value) : 0,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          });
        })

      return true;
    }
    return false;
  };

  const onDcSAChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setDcSA(valuesArray[1]);
    setDcSAReq(valuesArray[0]);
  };

  const onDcDeliveryChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setDcDelivery(valuesArray[1]);
    setDcDeliveryReq(valuesArray[0]);
  };

  const getIntersectionOptions = () => {
    let prepOptions = [];
    let storeDCs = props?.storeDCs;
    if (storeDCs?.length) {
      storeDCs.forEach((val, indx) => {
        prepOptions.push({
          label: `DC - ${val}`,
          value: `DC - ${val}`,
        });
      });
    }
    return prepOptions;
  };

  const prepInput = React.useMemo(
    () => [
      {
        id: 1,
        isRequired: false,
        label: "DC",
        name: "dc",
        className: "setAll__filter",
        dropdownValue: dcSA,
        onDropDownChange: onDcSAChange,
        placeHolder: `Select DC`,
        optionsArray: getIntersectionOptions(),
        isMultiSelect: true,
      },
      {
        id: 2,
        isRequired: false,
        label: "Calendar",
        name: "calendar",
        className: "setAll__filter",
        input: true,
        Comp: (
          <DatePicker
            minOrmax={{ min: today }}
            onChange={(value) => setDateSA(dateFormat(value))}
            dateFormat="MM-DD-YYYY"
          />
        ),
      },
    ],
    [dcSA, props.tableInstance?.state?.selectedRowIds]
  );

  const prepInputDeliveryDate = React.useMemo(
    () => [
      {
        id: 3,
        isRequired: false,
        label: "DC",
        name: "dc",
        className: "setAll__filter",
        dropdownValue: dcDelivery,
        onDropDownChange: onDcDeliveryChange,
        placeHolder: `Select DC`,
        optionsArray: getIntersectionOptions(),
        isMultiSelect: true,
      },
      {
        id: 4,
        isRequired: false,
        label: "Calendar",
        name: "calendar",
        className: "setAll__filter",
        input: true,
        Comp: (
          <DatePicker
            minOrmax={{ min: today }}
            onChange={(value) => setDateDelivery(dateFormat(value))}
            dateFormat="MM-DD-YYYY"
          />
        ),
      },
    ],
    [dcDelivery, props.tableInstance?.state?.selectedRowIds]
  );

  const handleSetAllOk = () => {
    let setAllRows = Object.keys(props.tableInstance.state.selectedRowIds);
    if (dcSAReq?.length && dateSA) {
      setStoreData((oldData) => {
        let newData = cloneDeep(oldData);
        setAllRows.forEach((setAllRow) => {
          dcSAReq.forEach((dcReq) => {
            newData[setAllRow][dcReq.label] = dateSA;
            setUpdatedId((old) => {
                return [...old,  newData[setAllRow]?.store_code]
            })
          });
        });
        return newData;
      });
    }
    if (dcDeliveryReq?.length && dateDelivery) {
      setStoreData((oldData) => {
        let newData = cloneDeep(oldData);
        setAllRows.forEach((setAllRow) => {
          dcDeliveryReq.forEach((dcReq) => {
            newData[setAllRow][`Delivery-Date ${dcReq.label}`] = dateDelivery; // Make sure to provide proper Key here
            setUpdatedId((old) => {
                return [...old,  newData[setAllRow]?.store_code]
            })
          });
        });
        return newData;
      });
    }
  };

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  };

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  };

  const setAllHandler = (p_instance) => {
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      setShowSetAll(true);
      setDcSA(null);
      setDcSAReq(null);
      setDateSA(null);
      setDcDelivery(null);
      setDcDeliveryReq(null);
      setDateDelivery(null);
    } else {
      Notify.error(
        `Please select atleast 1 store to use Set All functionality!!`
      );
    }
  };

  const handleSave = (p_instance) => {
    const { allocationCode, articles, storeDCs, saveStoreData } = props;

    let uniqueUpdatedIds = [...new Set(updatedId)]
    let currentSelectedStores = p_instance?.data?.filter(val => uniqueUpdatedIds.includes(val.store_code))
    let stores = {};

    forEach(currentSelectedStores, (store) => {
      let shipping_date = {}
      let delivery_date = {}
      store.dc_names.map((dc, index) => {
        store[`DC - ${dc}`] &&
          (shipping_date[`${store.dc_codes[index]}`] = store[`DC - ${dc}`]
            ? dateFormat(store[`DC - ${dc}`])
            : "");
        store[`Delivery-Date DC - ${dc}`] &&
          (delivery_date[`${store.dc_codes[index]}`] = store[`Delivery-Date DC - ${dc}`]
            ? dateFormat(store[`Delivery-Date DC - ${dc}`])
            : "");
      });
      stores[`${store.store_code}`] = {
        shipping_date,
        delivery_date,
      };
    });

    let req = {
      stores: stores,
      allocation_code: allocationCode,
      articles: articles,
    };
    saveStoreData(req)
  };

  const prepareStoreGradeColumns = () => {
    let data = props.storeAllocationData[0];
    data?.store_grades?.map((sg, index) => {
      if (sg) {
        allocationDataColumns[6].columns.push({
          Header: sg + " ",
          accessor: (row) => row.store_allocated_qty[index],
          width: data?.store_grades?.length == 1 ? 200 : 100,
        });
        allocationDataColumns[7].columns.push({
          Header: sg,
          accessor: (row) => row.store_allocated_index[index],
          width: data?.store_grades?.length == 1 ? 200 : 100,
        });
      }
    });
    data?.dc &&
      Object.keys(data?.dc)?.map((val) => {
        if (val) {
          allocationDataColumns[4].columns.push({
            Header: val + " ",
            accessor: (row) => row.dc[val]["allocated_units_total"],
            Cell: (inst) => numberUSFormatting(inst?.value),
          });
          allocationDataColumns[5].columns.push({
            Header: val,
            accessor: (row) => row.dc[val]["inventory_available"],
            Cell: (inst) => numberUSFormatting(inst?.value),
          });
        }
      });
      allocationDataColumns[4].columns.push({
        Header: "Total Allocated",
        accessor: "allocated_units_total",
        Cell: (inst) => numberUSFormatting(inst?.value)
    })
  };

  return (
    <div className="store__view__tables">
      {/* <Notification /> */}
      {showSetAll && !props.viewAllocationCall && (
        <SetAllModal
          modalBodyStyle={{
            height: props.isPOASNFlow || props.isPOASN ? "43rem" : "33rem",
          }}
          heading="Set Dates"
          filter={prepInput}
          deliveryFilter={prepInputDeliveryDate}
          handleOk={handleSetAllOk}
          handleCancel={handleSetAllCancel}
          handleClose={() => handleClose()}
          isPOASNFlow={props.isPOASNFlow || props.isPOASN}
          disableApply={((dcSA && dateSA && dateSA !== "Invalid date") || (dcDelivery && dateDelivery && dateDelivery !== "Invalid date")) ? false : true }
        />
      )}
      <div className="f__table">
        <PageLoader loader={props.storeDataLoading} gridLoader={true}>
          <div className="storesStore__heading">
            <h1 className="fnt-md fnt-bold">Allocation Summary</h1>
          </div>
          {prepareStoreGradeColumns()}
          {props.storeDataError ? (
            <div className="error">{props.storeDataError}</div>
          ) : props.storeAllocationData ? (
            <ReactTableWithPlugins
              data={props.storeAllocationData ? props.storeAllocationData : []}
              columns={allocationDataColumns}
              renderMarkup="TableMarkup"
              tableId="finalize_store_view"
            />
          ) : null}
        </PageLoader>
      </div>
      <div className="f__table">
        {/* <PageLoader loader={props.storeDataLoading} gridLoader={true}> */}
        {setDateColumns()}
        {props.storeDataError ? (
          <div className="error">{props.storeDataError}</div>
        ) : storeData.length ? (
          <>
            <div className="storesStore__heading">
              <h1 className="fnt-md fnt-bold">Stores Details</h1>
            </div>
            <ReactTableWithPlugins
              // shouldPagination
              shouldPagination
              // embededScroll
              tableWrapperStyle={{ height: 'fit-content', maxHeight: '60rem' }}
              headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
              totalRecordsLen={storeData ? storeData.length : 0}
              data={storeData ? storeData : []}
              sortBy={[{ id: "store_code", desc: false }]}
              columns={storeDatacolumns}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              renderMarkup="TableMarkup"
              keyRT={keyRT}
              getInstanceOnMount={(instance) => {
                setTableInstance(instance);
              }}
              tableId="finalize_store_details"
              pageSize={getNumberOfRecordsPerPages(
                "finalize_store_view",
                storeData.length
              )}
              initialHiddenColumns={initialHiddenColumns}
            />
          </>
        ) : null}
        {/* </PageLoader> */}
        {!props.viewAllocationCall &&
          !props.isFinalized &&
          !props?.storeDataError && !props.allocationStatus && (
            <div div style={{ textAlign: "center", margin: "2rem" }}>
              <button
                style={{ margin: "2px 1rem" }}
                className="btn btn-primary"
                onClick={() => setAllHandler(props.tableInstance)}
              >
                Set Dates
              </button>
              <button
                style={{ margin: "2px 1rem" }}
                className="btn btn-primary"
                onClick={() => handleSave(props.tableInstance)}
              >
                Save
              </button>
            </div>
          )}
      </div>
      <hr />
      <div className="store__products__table f__table">
        <PageLoader
          loader={props.storeDetailsDataLoading && !props.storeDataLoading}
          gridLoader={true}
        >
          {props.storeDetailsDataError ? (
            <div className="error">{props.storeDetailsDataError}</div>
          ) : props.storeDetails.data.length ? (
            setProductListSizeColumns() && (
              <>
                <div className="storesStore__heading">
                  <h1 className="fnt-md fnt-bold">
                    {selectedStore?.store_code} ({selectedStore?.store_name})
                  </h1>
                  <h1 className="fnt-md fnt-bold">Articles</h1>
                </div>
                <ProductListTable
                  data={props.storeDetails.data}
                  sizes={props.storeDetails.sizes}
                  columns={storeProductDatacolumns}
                  initialHiddenColumns={initialHiddenColumns}
                />
              </>
            )
          ) : null}
        </PageLoader>
      </div>

      <div className="f__table">
        <PageLoader
          loader={
            props.storeProductDetailsLoading && !props.storeDetailsDataLoading
          }
          gridLoader={true}
        >
          {props.storeProductDetailsError ? (
            <div className="error">{props.storeProductDetailsError}</div>
          ) : props.storeProductDetails.data.length ? (
            <ProductDetailsTable
              isFinalized={props.isFinalized}
              viewAllocationCall={props.viewAllocationCall}
              releasedDate={props.releasedDate}
              callAllApis={callAllStoreApis}
              data={props.storeProductDetails.data}
              selectedRow={RTInstance}
              getProductDetails={getStoreProductDetails}
              sizes={props.storeProductDetails.sizes}
              productDetails={props.storeProductDetails}
              allocationcode={props.allocationCode}
              originalAllocationCode={props.originalAllocationCode}
              articles={props?.articles}
            />
          ) : null}
        </PageLoader>
      </div>
    </div>
  );
};

const mapStateToProps = ({ finalize }) => {
  return {
    storeDataLoading: finalize.storeDataLoading,
    storeData: finalize.storeData,
    storeDCs: finalize.storeDCs,
    storeDataError: finalize.storeDataError,
    storeDetailsDataLoading: finalize.storeDetailsDataLoading,
    storeDetails: finalize.storeDetailsData,
    storeDetailsDataError: finalize.storeDetailsDataError,
    storeProductDetailsLoading: finalize.storeProductDetailsLoading,
    storeProductDetails: finalize.storeProductDetails,
    storeProductDetailsError: finalize.storeProductDetailsError,
    storeAllocationData: finalize.storeAllocationData,
    saveSuccess: finalize.saveSuccess,
    allocationStatus: finalize.allocationStatus    
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreData: (payload) => dispatch(getStoreData(payload)),
  getStoreDetails: (payload) => dispatch(getStoreDetails(payload)),
  getStoreProductDetails: (payload) =>
    dispatch(getStoreProductDetails(payload)),
  getPastAllocationPO: (payload) => dispatch(getPastAllocationPO(payload)),
  saveStoreData: (payload) => dispatch(saveStoreData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreView);
